@import '../../theme/defaults';

.headerContainer {
	display: flex;
	background: $theme-red;
	padding: 25px;
	border: 1px transparent;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	div {
		display: flex;
		align-content: center;
		h1 {
			font-size: 34px;
			line-height: 55px;
			font-weight: 700;
			color: $white;
			padding: 0;
			margin: 0;
		}
		button {
			@include theme-button;
			background: rgba(255, 255, 255, 0.12);
			span {
				margin-top: -2px;
			}
		}
	}
	.assignment {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		margin-right: 15px;
		span {
			color: $white;
			text-transform: uppercase;
			font-size: 12px;
			line-height: 18px;
			opacity: 0.75;
			font-weight: 700;
			letter-spacing: 1px;
		}
		div {
			display: flex;
			flex-direction: row;
			align-items: center;
			min-height: 40px;
			img {
				width: 40px;
				height: 40px;
				margin-right: 5px;
				object-fit: cover;
				object-position: center;
				border: 1px transparent;
				border-radius: 50%;
			}
			span {
				color: $white;
				opacity: 1;
				margin-left: 5px;
			}
		}
	}
	> div:first-child {
		flex: 1;
	}
	> div:last-child {
		display: flex;
		justify-content: flex-end;
	}
}
