@import '../../theme/defaults';

.accountFormBackdropContainer {
	position: absolute;
	display: flex;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 99;
	background: rgba(0, 0, 0, 0.8);
	overflow-y: auto;
	justify-content: center;
	padding-bottom: 200px;
	padding-top: 50px;
	.accountFormContainer {
		background: $white;
		display: flex;
		width: 80vw;
		max-width: 1250px;
		border: 1px transparent;
		border-radius: 12px;
		flex-direction: column;
		position: absolute;
		.modalHeader {
			display: flex;
			width: 100%;
			position: relative;
			max-height: 80px;
			div {
				flex: 1;
				padding: 30px 25px;
				h3 {
					font-size: 18px;
					line-height: 21px;
					letter-spacing: 1px;
					text-transform: uppercase;
					font-weight: 700;
					margin: 0;
				}
			}
			button {
			
				&.primaryButton {
					@include theme-button;
					min-width: 200px;
				}
				&.closeButton {
					position: absolute;
					background: none;
					outline: none;
					border: 0;
					right: 10px;
					top: 5px;
					font-size: 18px;
					opacity: 0.5;
					padding: 10px;
					-webkit-transition: all 0.2s ease-in-out;
					-moz-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
					&:hover {
						opacity: 1;
					}
				}
				
			}
		}
		.modalBody {
			padding: 0 25px;
			display: flex;
			flex-direction: column;
			width: 100%;
			flex: 1;
			> div {
				display: inline-flex;
				flex-direction: column;
				flex: 1;
			}
			.styledHeading {
				@include styled-heading;
				margin-top: 10px;
			}
			.styledSubHeading {
				@include styled-heading;
				background: none;
				padding-left: 0;
				text-transform: capitalize;
				.inlineError {
					display: inline-block;
					background: $theme-red-bg;
					padding: 2px;
					border: 1px transparent;
					border-radius: 4px;
					margin-right: 10px;
					color: $error-color;
					fill: $error-color;
					font-size: 16px;
				}
			}
			.formControlContainer {
				@include form-control-container;
			}
			.removeBeneficiary {
				background: $theme-red-bg;
				padding: 2px 5px;
				outline: none;
				border: 1px transparent;
				font-size: 12px;
				margin-left: 10px;
				border-radius: 4px;
				color: $theme-red;
				&:hover {
					background: $theme-red-bg;
					color: $theme-red;
					font-weight: bold;
				}
			}
			.closeSection {
				&::after {
					content: '';
					background: $question-bg;
					height: 2px;
					border: 0;
					width: 300px;
					display: block;
				}
			}
		}
		.modalFooter {
			padding: 30px 25px;
			display: flex;
			flex-direction: row;
			width: 100%;
			flex: 1;
			button {
				@include theme-button;
				min-width: 200px;
			}
			button:last-child {
				@include theme-button-secondary;
			}
		}
	}
}
