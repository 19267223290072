@import '../../theme/defaults';

.styledHeading {
	@include styled-heading;
	margin-top: 25px;
}

.formControlContainer {
	@include form-control-container;
}

.formInlineActionContainer {
	margin-top: 10px;
}
