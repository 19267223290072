@import '../../theme/defaults';

.summaryHeader {
	font-size: 22px;
	line-height: 24px;
	font-weight: 700;
}

.summaryBoxHeader {
	display: flex;
	flex-direction: row;
	width: 100%;
	> div {
		display: flex;
		align-items: center;
	}
	> div:last-child {
		display: flex;
		flex: 1;
		flex-direction: row;
		justify-content: flex-end;
		> div {
			background: rgba(55, 114, 184, 0.08);
			padding: 12px;
			border: 1px transparent;
			border-radius: 4px;
			margin-left: 10px;
			min-width: 130px;
			p {
				text-transform: uppercase;
				font-size: 12px;
				line-height: 12px;
				padding: 0;
				margin: 0;
			}
			span {
				font-size: 22px;
				font-weight: 700;
				line-height: 40px;
			}
			span.needAction {
				color: #CC433C;
			}
		}
	}
}

.summaryBoxFooter {
	display: flex;
	justify-content: flex-end;
}
