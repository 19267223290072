@import '../../theme/defaults';

.qtTable {
	@include simple-table;
	tbody {
		tr {
			td {
				width: 50%;
				padding: 0;
			}
		}
	}
}
