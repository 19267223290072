@import '../../theme/defaults';

.radioButtonContainer {
	/* Customize the label (the container) */
	label {
		display: block;
		position: relative;
		padding-left: 35px;
		font-size: 14px;
		font-weight: 700;
		line-height: 18px;
		color: #272429;
		text-transform: capitalize;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	
	/* Hide the browser's default radio button */
	label input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	
	/* Create a custom radio button */
	span {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: $theme-red;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 0.7;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}
	
	/* On mouse-over, add a grey background color */
	label:hover input ~ span{
		background-color: $theme-red;
		opacity: 1;
	}
	
	/* When the radio button is checked, add a blue background */
	label input:checked ~ span {
		background-color: $theme-red;
		opacity: 1;
	}
	
	/* Create the indicator (the dot/circle - hidden when not checked) */
	span:after {
		content: "";
		display: none;
	}
	
	/* Show the indicator (dot/circle) when checked */
	label input:checked ~ span:after {
		display: inline-flex;
	}
	
	/* Style the indicator (dot/circle) */
	label span:after {
		width: 14px;
		height: 14px;
		border-radius: 50%;
		background: $theme-red;
		border: 3px solid white;
	}
}
