@import '../../theme/defaults';

.dropzoneContainer {
	min-height: 245px;
	&.disabled {
		cursor: not-allowed;
	}
	label {
		font-size: 14px;
		font-weight: 700;
		line-height: 18px;
		color: $text-dark;
		text-transform: capitalize;
	}
	.dropzone {
		width: 100%;
		height: 100%;
		background: $grey-bg;
		min-height: 128px;
		display: flex;
		justify-content: center;
		align-items: center;
		&.dropzoneActive {
			background: $theme-red-bg;
		}
		p {
			text-align: center;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}
}
