@import '../../theme/defaults';

.styledHeading {
	@include styled-heading;
	margin-top: 25px;
}

.formControlContainer {
	@include form-control-container;
	padding: 15px;
	&:nth-child(odd) {
		background: $question-bg;
	}
	&.questionOnly {
		padding: 15px;
	}
	& > div.radioContainer {
		flex-direction: row;
		& > div {
			display: flex;
			flex: 1 1 25%;
			align-items: center;
		}
		& > div.labelText {
			display: flex;
			flex: 1 1 75%;
			font-size: 14px;
			font-weight: 700;
			line-height: 18px;
			color: $text-dark;
			padding-right: 10px;
		}
	}
}

.formInlineActionContainer {
	margin-top: 10px;
}
