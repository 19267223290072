@import '../../theme/defaults';

.formControl {
	display: flex;
	width: 100%;
	flex-direction: column;
	padding: 10px 0;
	label {
		font-size: 14px;
		font-weight: 700;
		line-height: 18px;
		color: $text-dark;
		text-transform: capitalize;
	}
	input[type=text], input[type=number] {
		width: 100%;
		padding: 12px;
		color: $text-dark-2;
		font-size: 14px;
		font-weight: 600;
		border: 2px solid $grey-border;
		border-radius: 4px;
		outline: none;
		&:active, &:focus {
			border-color: $grey-border;
			box-shadow: none;
		}
		&.missing {
			background: $fc-missing-bg;
			border-color: $fc-missing-border;
		}
	}
	input[type=file] {
		width: 100%;
		padding: 9px;
		color: $text-dark-2;
		font-size: 14px;
		font-weight: 600;
		border: 2px solid $grey-border;
		border-radius: 4px;
		outline: none;
		&:active, &:focus {
			border-color: $grey-border;
		}
		&.missing {
			background: $fc-missing-bg;
			border-color: $fc-missing-border;
		}
		&::-webkit-file-upload-button,
		&::file-selector-button {
			background: $theme-red-bg;
			color: $theme-red;
			border: 1px transparent;
			border-radius: 4px;
			text-transform: uppercase;
			font-weight: bold;
			font-size: 12px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			padding: 5px 15px;
			-webkit-transition: all 0.1s ease-in-out;
			-moz-transition: all 0.1s ease-in-out;
			transition: all 0.1s ease-in-out;
		}
		&::-webkit-file-upload-button:hover,
		&::file-selector-button:hover {
			background: $theme-red;
			color: $white;
		}
	}
	.dropDown {
		> div[class$="-control"] {
			width: 100%;
			padding: 5px;
			color: #40434D;
			font-size: 14px;
			font-weight: 600;
			border: 2px solid #E9EDF3;
			border-radius: 4px;
			outline: none;
		}
	}
	.missingDropDown {
		> div[class$="-control"] {
			background: $fc-missing-bg;
			border-color: $fc-missing-border;
		}
	}
	.warningText {
		color: $icon-grey;
		font-size: 12px;
		line-height: 18px;
		font-weight: 500;
		font-style: italic;
	}
}

//TODO: CLEAN UP HERE AND TURN THE BELOW CSS TO PROPER SCSS

.formCheckboxContainer {
	padding-top: 38px;
}

.checkbox {
	--background: #fff;
	--border: #D1D6EE;
	--border-hover: #BBC1E1;
	--border-active: #CC433C;
	--tick: #fff;
	position: relative;
	display: flex;
	flex: 1;
	line-height: 20px !important;
}
.checkbox input,
.checkbox svg {
	width: 21px;
	height: 21px;
	display: block;
}
.checkbox input[type=checkbox] {
	-webkit-appearance: none;
	-moz-appearance: none;
	position: relative;
	outline: none;
	background: var(--background);
	border: none;
	margin: 0 15px 0 0;
	padding: 0;
	cursor: pointer;
	border-radius: 4px;
	transition: box-shadow 0.3s;
	box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
}
.checkbox input:hover {
	--s: 2px;
	--b: var(--border-hover);
}
.checkbox input:checked {
	--b: var(--border-active);
}
.checkbox svg {
	pointer-events: none;
	fill: none;
	stroke-width: 2px;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke: var(--stroke, var(--border-active));
	position: absolute;
	top: 0;
	left: 0;
	width: 21px;
	height: 21px;
	transform: scale(var(--scale, 1)) translateZ(0);
}
.checkbox.path input:checked {
	--s: 2px;
	transition-delay: 0.4s;
}
.checkbox.path input:checked + svg {
	--a: 16.1 86.12;
	--o: 102.22;
}
.checkbox.path svg {
	stroke-dasharray: var(--a, 86.12);
	stroke-dashoffset: var(--o, 86.12);
	transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
}
.checkbox.bounce {
	--stroke: var(--tick);
}
.checkbox.bounce input:checked {
	--s: 11px;
}
.checkbox.bounce input:checked + svg {
	-webkit-animation: bounce 0.4s linear forwards 0.2s;
	animation: bounce 0.4s linear forwards 0.2s;
}
.checkbox.bounce svg {
	--scale: 0;
}

@-webkit-keyframes bounce {
	50% {
		transform: scale(1.2);
	}
	75% {
		transform: scale(0.9);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes bounce {
	50% {
		transform: scale(1.2);
	}
	75% {
		transform: scale(0.9);
	}
	100% {
		transform: scale(1);
	}
}

.datePickerContainer {
	position: relative;
	span {
		position: absolute;
		z-index: 9;
		top: 14px;
		left: 10px;
	}
	&.missing {
		span {
			color: $theme-red;
		}
	}
	> div {
		width: 100%;
		input {
			padding-left: 35px !important;
		}
	}
}

.dragDiv {
	border: 1px solid #cccccc;
	padding: 10px 10px 0px 10px;
	border-radius: 5px;
	background: $grey-bg;
	cursor: move;
  }

.displayFlex{
	display: flex;
}

.dragIcon {
	margin-right: 5px;
	& path{
		stroke: #7a7a7a;
	}
}