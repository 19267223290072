@import '../../theme/defaults';

.tabsContainer {
	position: relative;
}

.docusignButton {
	position: absolute;
	bottom: 0;
	right: 50px;
	width: auto;
	height: 100px;
	button {
		background: $docusign-bg;
		color: $docusign-text;
		font-size: 18px;
		padding: 10px 35px;
		border: 1px transparent;
		border-radius: 50px;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		margin-top: 5px;
		&:hover {
			margin-top: 0px;
		}
	}
}