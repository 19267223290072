@import '../../theme/defaults';

.breadCrumbsContainer {
	display: flex;
	width: 100%;
}

.breadCrumbs {
	list-style: none;
	padding-left: 0;
	margin-left: 0;
	li {
		float: left;
		padding-right: 10px;
		button {
			background: none;
			border: 0;
			outline: none;
			font-size: 11px;
			line-height: 40px;
			text-transform: uppercase;
			font-weight: bold;
			color: $icon-grey;
			padding: 0;
			letter-spacing: 1px;
			-webkit-transition: all 0.2s ease-in-out;
			-moz-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
		}
		&::before {
			content: '\00003e';
			color: $icon-grey;
			margin-right: 10px;
			font-weight: 700;
		}
		&:first-child {
			&::before {
				display: none;
			}
		}
		&:last-child,
		&:hover {
			button {
				color: $theme-red;
			}
		}
	}
}
