@import './colors';

@mixin theme-badge {
	display: inline-flex;
	justify-self: flex-end;
	justify-content: center;
	align-items: center;
	padding: 2px 8px;
	font-size: 12px;
	color: $theme-red;
	background: $theme-red-bg;
	border: 1px transparent;
	border-radius: 100px;
	font-weight: 700;
}

@mixin theme-button {
	display: flex;
	justify-content: center;
	align-items: center;
	background: $theme-red;
	color: $white;
	padding: 13px 16px;
	border: 1px transparent;
	border-radius: 4px;
	margin-left: 16px;
	font-weight: bold;
	font-size: 14px;
	line-height: 14px;
	span {
		margin-right: 10px;
		color: $white;
		padding: 0;
	}
}

@mixin theme-button-secondary {
	display: flex;
	justify-content: center;
	align-items: center;
	background: $btn-secondary-bg;
	color: $theme-red;
	padding: 13px 16px;
	border: 1px transparent;
	border-radius: 4px;
	margin-left: 16px;
	font-weight: bold;
	font-size: 14px;
	line-height: 14px;
	span {
		margin-right: 10px;
		color: $white;
		padding: 0;
	}
}

@mixin styled-heading {
	font-size: 12px;
	font-weight: 700;
	line-height: 14px;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: $theme-red;
	width: 100%;
	background: $table-row-bg;
	padding: 5px;
}

@mixin form-control-container {
	display: flex;
	flex-direction: row;
	> div {
		flex: 1;
		display: flex;
		width: 100%;
		flex-direction: column;
		margin-right: 56px;
	}
	> div:last-child {
		margin-right: 0;
	}
}

@mixin alert-info {
	color: $alert-info-txt;
	background-color: $alert-info-bg;
	position: relative;
	padding: .75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid $alert-info-border;
	border-radius: .25rem;
	font-weight: 500;
}

@mixin simple-table {
	width: 100%;
	thead {
		border-bottom: 1px solid $table-row-bg;
		tr {
			th {
				color: $theme-red;
				font-size: 10px;
				line-height: 16px;
				text-transform: uppercase;
				padding: 10px 0;
				position: -webkit-sticky;
				position: sticky;
				top: 0;
				background: $white;
			}
		}
	}
	tbody {
		tr {
			margin-top: 5px;
			&:hover {
				background: $table-row-bg;
			}
			&.spacerRow {
				height: 8px;
			}
			td {
				padding: 10px 0;
				font-size: 14px;
				color: $text-dark-2;
				border-bottom: 1px solid $table-row-bg;
			}
			td:first-child {
				font-weight: 700;
			}
			td:nth-child(2) {
				text-transform: capitalize;
			}
		}
	}
}
