.overflow-panel {
    overflow-x: auto;
    min-height: 0.01%;
    width: 300px;
    margin: 100px;
  }
  
  .wide-load {
    width: 600px;
    height: 800px;
    background-color: #f0f0f0;
  }
  
  
  /* floating-scroll stuff */
  .fl-scrolls {
      bottom:0;
      height:35px;
      overflow:auto;
      position:fixed;
      div {
        height:1px;
        overflow:hidden;
        &:before {
            content:""; /* fixes #6 */
        }
    }
  }
  .fl-scrolls-hidden {
      bottom:9999px;
  }