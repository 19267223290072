@import '../../theme/defaults';

.householdsListContainerHeader {
	display: flex;
	justify-content: center;
	align-items: center;
	> div {
		flex: 1;
		display: flex;
	}
	div:last-child {
		justify-content: flex-end;
	}
}
