@import './colors';
@import './mixins';
@import './floatingScroll.scss';
.badge {
	@include theme-badge;
}

.viewAllButton {
	background: none;
	border: 0;
	color: $theme-red;
	font-size: 12px;
	line-height: 16px;
	font-weight: 700;
}

.themeButton {
	@include theme-button;
}

.tabNavContainer {
	background: $grey-bg;
	padding: 4px;
	height: 40px;
	border: 1px transparent;
	border-radius: 5px;
	display: inline-flex;
	button {
		background: none;
		border: 1px transparent;
		border-radius: 5px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		min-width: 160px;
		height: 100%;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 12px;
		&.activeBtn {
			background: $white;
			color: $theme-red;
		}
		span {
			@include theme-badge;
			margin-left: 10px;
		}
	}
}

.themeTableAction {
	background: $theme-red-bg;
	color: $theme-red;
	border: 1px transparent;
	border-radius: 4px;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 5px 15px;
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
	&:active, &:hover {
		background: $theme-red;
		color: $white;
	}
}

.table.tableWithCollapsableRows {
	margin-top: 10px;
	width: 100%;
	thead {
		border-bottom: 1px solid $table-row-bg;
		th {
			color: $theme-red;
			font-size: 10px;
			line-height: 16px;
			text-transform: uppercase;
			padding: 10px 20px;
			button {
				padding: 0 !important;
				background: none;
				outline: none;
				border: transparent;
				color: $theme-red;
				font-size: 10px;
				font-weight: bold;
				line-height: 16px;
				text-transform: uppercase;
			}
		}
	}
	tbody {
		tr {
			margin-top: 5px;
			cursor: pointer;
			&.spacerRow {
				height: 8px;
			}
			td {
				padding: 20px 20px;
				font-size: 14px;
				color: $text-dark-2;
				background: $table-row-bg;
			}
			td:first-child {
				font-weight: 700;
				font-size: 16px;
				width: 500px;
			}
		}
	}
}

.table.nestedTable {
	margin-top: 5px;
	width: 100%;
	thead {
		th {
			background: $white;
		}
	}
	tbody {
		tr {
			td {
				background: $white;
				border-bottom: 1px solid $grey-border;
			}
			td:first-child {
				font-weight: 700;
				font-size: 16px;
				min-width: 350px;
			}
		}
	}
}

.table.tableWithEditableRows {
	margin-top: 10px;
	width: 100%;
	thead {
		border-bottom: 1px solid $table-row-bg;
		th {
			color: $theme-red;
			font-size: 10px;
			line-height: 16px;
			text-transform: uppercase;
			padding: 10px 20px;
			button {
				padding: 0 !important;
				background: none;
				outline: none;
				border: transparent;
				color: $theme-red;
				font-size: 10px;
				font-weight: bold;
				line-height: 16px;
				text-transform: uppercase;
			}
		}
	}
	tbody {
		tr {
			margin-top: 5px;
			td {
				min-width: 200px;
				padding: 15px;
				font-size: 14px;
				font-weight: bold;
				color: $text-dark-2;
				border-bottom: 1px solid $grey-border;
			}
		}
	}
}

.nestedTableContainer {
	padding: 0 !important;
	border-bottom: 2px solid $table-row-bg;
	border-left: 2px solid $table-row-bg;
	border-right: 2px solid $table-row-bg;
}

.nestedTableRowContainer {
	border-left: 2px solid $table-row-bg;
	border-top: 2px solid $table-row-bg;
	border-right: 2px solid $table-row-bg;
}

.textCenter {
	text-align: center;
}

.tableColumnSmall {
	width: 120px;
}

.tableColumnExSmall {
	width: 50px;
}

.infoText {
	color: $info-text;
}

.successText {
	color: $success-text;
}

.dangerText {
	color: $danger-text;
}

.primaryText {
	color: $primary-text;
}

.addressLabel{
	color: $theme-red;
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
	padding: 10px 0px 0px 0px;
}

.editColumnsButton {
	background: none;
	border: 0;
	outline: none;
	color: $text-dark;
	font-size: 13px;
	line-height: 16px;
	text-transform: capitalize;
	font-weight: bold;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	&:hover, &:active {
		opacity: 1;
		color: $theme-red;
	}
}

.positionRelative {
  position: relative;
}

.addMoreBtn {
	background: none;
	outline: none;
	color: $theme-red;
	border: 0;
	font-weight: bold;
	font-size: 13px;
	margin: 15px 0;
}
