@import '../../theme/defaults';

.pageHeader {
	display: flex;
	flex-direction: row;
	div {
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: flex-end;
	}
}

.edit-btn{
	margin-right: 16px;
}