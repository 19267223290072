@import '../../theme/defaults';

.collapsableContainer {
	padding: 3px 0;
	width: 100%;
	.collapsableTitle {
		background: none;
		border: 1px solid $white;
		outline: none;
		padding: 5px 5px;
		font-size: 15px;
		border-radius: 6px;
		width: 100%;
		text-align: left;
		font-style: italic;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		&:hover {
			background: $table-row-bg;
			border-color: $table-row-bg;
		}
		span {
			font-weight: bold;
			font-style: normal;
		}
		span:last-child {
			font-weight: 500;
			font-size: 11px;
			margin-left: 5px;
			color: $primary-text;
			&::before {
				content: '\00003c\00003c';
				display: inline-block;
				margin-right: 2px;
			}
			&::after {
				content: '\00003e\00003e';
				display: inline-block;
				margin-left: 2px;
			}
		}
	}
	&.expanded {
		.collapsableTitle {
			background: $table-row-bg;
			border: 1px solid $grey-border;
			border-bottom: 1px transparent;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
			-webkit-transition: all 0.2s ease-in-out;
			-moz-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
		}
		.collapsedContent {
			padding: 10px;
			border: 1px solid $grey-border;
			border-top: 1px transparent;
		}
	}
}
