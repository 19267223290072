@import '../../theme/defaults';

.popover__title {
	font-size: 24px;
	line-height: 36px;
	text-decoration: none;
	color: rgb(228, 68, 68);
	text-align: center;
	padding: 15px 0;
}

.popover__wrapper {
	width: max-content;
	position: absolute;
	display: inline-block;
	left: -8vh;
	top: 5vh;
	z-index: 11;
	box-shadow: 0 2px 5px 0 #00000042;
	transform: translate(0, 10px);
	transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
	
	&::-webkit-scrollbar {
		width: 16px;
	}
	
	&::-webkit-scrollbar-track {
		background: $white;
	}
	
	&::-webkit-scrollbar-thumb {
		background-color: $text-dark-2;
		border-radius: 10px;
		border: 6px solid $white;
	}
}

.popover__content {
	height: 30vh;
	overflow: auto;
	border-radius: 5px;
	z-index: 10;
	opacity: 1;
	background-color: $white;
	padding: 1.5rem;
	
	&:before {
		position: absolute;
		z-index: -1;
		content: "";
		right: calc(50% - 10px);
		top: -10px;
		border-style: solid;
		border-width: 0 10px 10px;
		border-color: $grey-bg transparent;
		display: inline-block;
	}
}

.modalFooter {
	padding: 10px 10px;
	display: flex;
	flex-direction: row;
	flex: 1 1;
	z-index: 11;
	background: $white;
	border-top: 1px solid #e8e8e8;
	
	button {
		@include theme-button;
		min-width: fit-content;
	}
	
	button:last-child {
		@include theme-button-secondary;
	}
	
}
  