@import '../../theme/defaults';

.formContainer {
	margin-left: 16px;
	position: relative;
	span {
		position: absolute;
		left: 10px;
		top: 5px;
		font-size: 18px;
		color: $icon-grey;
	}
	.inputField {
		outline: none;
		border: 2px solid $grey-border;
		border-radius: 4px;
		color: $text-dark-2;
		font-size: 14px;
		line-height: 18px;
		padding: 10px 10px 10px 35px;
		min-width: 250px;
	}
}
