@import '../../theme/defaults';

.documentPreviewContainer {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 248px;
	&::after {
		content: "";
		display: block;
	}
	.content {
		width: 100%;
		height: 100%;
		label {
			font-size: 14px;
			font-weight: 700;
			line-height: 18px;
			color: $text-dark;
			text-transform: capitalize;
		}
		.imageContainer {
			border: 2px solid $theme-red-bg;
			padding: 7px;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			background: rgba(204, 67, 60, 0.09);
			max-height: 345px;
			img {
				height: 90%;
				width: 100%;
				object-fit: cover;
				&.pdfIcon {
					width: 49px;
					height: 49px;
				}
			}
			p {
				padding: 0;
				margin-bottom: 0;
				margin-top: 5px;
			}
		}
	}
}
