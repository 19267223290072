@import '../../theme/defaults';

.tabsNavContainer {
	background: $white;
	width: 100%;
	border: 1px transparent;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			float: left;
			button {
				background: none;
				border: 4px transparent;
				outline: none;
				padding: 20px 25px;
				font-size: 14px;
				line-height: 18px;
				font-weight: 700;
				letter-spacing: 1px;
				text-align: center;
				color: $icon-grey;
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
				&.active,
				&:hover {
					color: $theme-red;
					border-bottom: 4px solid $theme-red;
				}
			}
			button.createNewContactBtn {
				background: none;
				border: 4px transparent;
				outline: none;
				padding: 12px 20px;
				font-size: 12px;
				line-height: 18px;
				font-weight: 700;
				letter-spacing: 1px;
				text-align: center;
				color: $theme-red;
				border-radius: 8px;
				margin-top: 12px;
				margin-left: 20px;
				background: $theme-red-bg;
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
				&[disabled] {
					cursor: not-allowed;
					color: $icon-grey;
				}
			}
		}
	}
}
