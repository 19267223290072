// brand colors

$theme-red: #CC433C;
$theme-red-bg: rgba(204, 67, 60, 0.2);
$white: #ffffff;
$grey-bg: rgba(233, 237, 243, 0.75);
$grey-border: #E9EDF3;
$text-dark: #272429;
$text-dark-2: #40434D;
$table-row-bg: #F7F8F9;
$icon-grey: #84878F;
$fc-missing-bg: rgba(204, 67, 60, 0.04);
$fc-missing-border: rgba(204, 67, 60, 0.25);
$btn-secondary-bg: rgba(94, 21, 65, 0.08);
$alert-info-txt: #0c5460;
$alert-info-bg: #d1ecf1;
$alert-info-border: #bee5eb;
//bootstrap colors
$success-text: #28a745;
$danger-text: #dc3545;
$info-text: #17a2b8;
$primary-text: #007bff;
//bootstrap colors
$question-bg: #fff8f7;
$docusign-bg: #D6FB00;
$docusign-text: #000;
$error-color: #cc3300;
