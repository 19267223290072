@import '../../theme/defaults';

.listContainerHeader {
	display: flex;
	justify-content: center;
	align-items: center;
	
	> div {
		flex: 1;
		display: flex;
	}
	
	div:last-child {
		justify-content: flex-end;
	}
}

.tableContainer {
	overflow: auto;
	padding-bottom: 20px;
	height: 75vh;
	
	& {
		scrollbar-width: auto;
		scrollbar-color: $text-dark-2 $white;
	}
	
	/* Chrome, Edge, and Safari */
	&::-webkit-scrollbar {
		width: 16px;
	}
	
	&::-webkit-scrollbar-track {
		background: $white;
	}
	
	&::-webkit-scrollbar-thumb {
		background-color: $text-dark-2;
		border-radius: 10px;
		border: 6px solid $white;
	}
}

.fixedColumn {
	min-width: 100px !important;
	background-color: white;
	padding: 18px;
	right: 0;
	position: sticky;
	text-align: center;
	border-left: 1px solid $grey-border;
	border-bottom: 1px solid $grey-border;
}

.fixedColumn:before {
	border-left: 1px solid #F7F8F9;
	content: "";
	position: absolute;
	left: -1px;
	top: 0px;
	width: 1px;
	height: 100%;
}

.fixedColumn:after {
	border-bottom: 1px solid #F7F8F9;
	content: "";
	position: absolute;
	left: -1px;
	bottom: 0px;
	width: 100%;
	height: 1px;
}