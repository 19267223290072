@import '../../theme/defaults';

.sideNavLinkItem {
	button {
		display: flex;
		background: none;
		width: 100%;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		color: $white;
		outline: none;
		border: none;
		padding: 15px 5px;
		position: relative;
		opacity: 0.7;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		&:hover,
		&.active {
			opacity: 1;
			&::after {
				content: '';
				width: 5px;
				height: 100%;
				position: absolute;
				background: $white;
				margin-left: -30px;
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
			}
		}
		svg {
			margin-right: 15px;
		}
		div {
			flex: 1;
			display: inline-flex;
			flex-direction: row;
			justify-content: flex-start;
			span {
				margin-left: auto;
			}
		}
	}
	&.subItem {
		button {
			padding-left: 36px;
			&:hover,
			&.active {
				&::after {
					margin-left: -61px;
				}
			}
		}
	}
}

.sidebarBadge {
	@include theme-badge;
	background: $theme-red;
	color: $white;
}
