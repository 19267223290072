@import '../../theme/defaults';

.spacerRow {
	height: 8px;
}

.inlineTextField {
	font-weight: bold;
	font-size: 14px;
	padding: 5px;
	border: 2px solid transparent;
	border-radius: 4px;
	outline: none;
	background: none;
	width: 100%;
	&:hover, &:focus {
		border: 2px solid $grey-border;
	}
	&.inlineTextFieldMissing {
		background: $theme-red-bg;
		border: 2px solid $theme-red;
	}
}

.actionContainer {
	min-width: 100px !important;
	padding: 19px !important;
	background-color: white;
	right: 0;
	position: sticky;
	border-left: 1px solid $grey-border;
}

.actionContainer:before {
	border-left: 1px solid #E9EDF3;
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 1px;
    height: 100%;
}
