@import '../../theme/defaults';

.bulkImportModalContainer {
	position: absolute;
	display: flex;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 99;
	background: rgba(0, 0, 0, 0.8);
	overflow-y: auto;
	justify-content: center;
	padding-bottom: 200px;
	padding-top: 50px;
	.contentContainer {
		background: $white;
		display: flex;
		width: 80vw;
		max-width: 1250px;
		border: 1px transparent;
		border-radius: 12px;
		flex-direction: column;
		position: absolute;
		.modalHeader {
			display: flex;
			width: 100%;
			position: relative;
			max-height: 80px;
			border-bottom: 1px solid $grey-border;
			div {
				flex: 1;
				padding: 30px 25px;
				h3 {
					font-size: 18px;
					line-height: 21px;
					letter-spacing: 1px;
					text-transform: uppercase;
					font-weight: 700;
				}
			}
			button {
				position: absolute;
				background: none;
				outline: none;
				border: 0;
				right: 10px;
				top: 5px;
				font-size: 18px;
				opacity: 0.5;
				padding: 10px;
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
				&:hover {
					opacity: 1;
				}
			}
			.modalTitleContainer {
				span {
					color: $theme-red;
				}
			}
		}
		.modalBody {
			padding: 20px 25px;
			display: flex;
			flex-direction: column;
			width: 100%;
			flex: 1;
			.modalActionsContainer {
				display: flex;
				flex-direction: row;
				> div {
					display: flex;
					flex-direction: row;
					flex: 1 0;
					> div {
						flex: 1 0;
						margin-right: 10px;
					}
				}
				> div:last-child {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-end;
					button {
						@include theme-button-secondary;
						-webkit-transition: all 0.2s ease-in-out;
						-moz-transition: all 0.2s ease-in-out;
						transition: all 0.2s ease-in-out;
						&:hover {
							background: $theme-red;
							color: $white;
						}
					}
				}
			}
			.fieldsSummaryContainer,
			.fieldsMappingContainer {
				margin-top: 15px;
				display: flex;
				flex-direction: column;
				h3 {
					font-size: 18px;
					line-height: 21px;
					letter-spacing: 1px;
					text-transform: uppercase;
					font-weight: 700;
				}
				.tableContainer {
					display: flex;
					max-height: 200px;
					overflow-y: auto;
				}
			}
			.fieldsMappingContainer {
				margin-top: 25px;
				.noFileSelected {
					@include alert-info;
				}
				.tableContainer {
					max-height: none;
				}
			}
		}
		.modalFooter {
			padding: 30px 25px;
			display: flex;
			flex-direction: row;
			width: 100%;
			flex: 1;
			button {
				@include theme-button;
				min-width: 200px;
			}
			button:last-child {
				@include theme-button-secondary;
			}
		}
	}
}
