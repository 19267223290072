@import '~react-toastify/dist/ReactToastify.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~axios-progress-bar/dist/nprogress.css';

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.meter {
	box-sizing: content-box;
	height: 8px; /* Can be anything */
	position: relative;
	background: #555;
	box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}

.meter > span {
	display: block;
	height: 100%;
	background-color: rgb(43, 194, 83);
	background-image: linear-gradient(
			center bottom,
			rgb(43, 194, 83) 37%,
			rgb(111, 238, 111) 69%
	);
	box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
	inset 0 -2px 6px rgba(0, 0, 0, 0.4);
	position: relative;
	overflow: hidden;
}

.meter > span:after,
.animate > span > span {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-image: linear-gradient(
			-45deg,
			rgba(255, 255, 255, 0.2) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, 0.2) 50%,
			rgba(255, 255, 255, 0.2) 75%,
			transparent 75%,
			transparent
	);
	z-index: 1;
	background-size: 50px 50px;
	animation: move 2s linear infinite;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	overflow: hidden;
}

.animate > span:after {
	display: none;
}

@keyframes move {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: 50px 50px;
	}
}

.orange > span {
	background-image: linear-gradient(#f1a165, #f36d0a);
}

.red > span {
	background-image: linear-gradient(#f0a3a3, #f42323);
}

.nostripes > span > span,
.nostripes > span::after {
	background-image: none;
}

.icon-spin {
	-webkit-animation: icon-spin 2s infinite linear;
	animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes icon-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

.app-version {
	position: absolute;
	bottom: 6px;
	right: 23px;
	font-size: 10px;
	padding: 0;
	margin: 0;
	background: none;
	outline: none;
	border: 0;
	color: #8e8e8e;
}

.app-version:hover {
	color: #000;
}
