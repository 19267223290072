.loadingIndicatorContainer {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	background: rgba(0,0,0,0.1);
}
