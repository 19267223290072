@import '../../theme/defaults';

.pageHeader {
	display: flex;
	flex-direction: row;
	div {
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: flex-end;
	}
}

.boxHeader {
	display: flex;
	flex-direction: row;
	align-items: center;
	div:first-child {
		flex: 1;
	}
	div:last-child {
		display: flex;
		justify-content: flex-end;
	}
}

.boxContent {
	padding-top: 25px;
	max-height: 75vh;
	overflow-y: auto;
}
